'use strict';

var app = angular.module('enervexSalesappApp', [
	'ngCookies',
	'ngResource',
	'ngSanitize',
	'ui.router',
	'ui.bootstrap',
	'ngFlash',
	'lodash',
	'720kb.tooltips',
	'angular.filter',
	'angularFileUpload',
	'dndLists',
	'n3-line-chart',
	'ui.bootstrap.contextMenu',
	'yaru22.angular-timeago',
	'AngularPrint',
	'autoCompleteModule',
	'ui.tinymce',
	'ngCropper',
	'mgcrea.bootstrap.affix',
	'LocalStorageModule',
	'color.picker'
]).config(function($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, localStorageServiceProvider, timeAgoSettings) {
	$urlRouterProvider.otherwise('/');
	$locationProvider.html5Mode(true);
	$httpProvider.interceptors.push('authInterceptor');
	localStorageServiceProvider.setPrefix('wisp').setDefaultToCookie(false);
	localStorageServiceProvider.setStorageType('localStorage');
	var oneDay = 60*60*24;
	timeAgoSettings.fullDateAfterSeconds = oneDay* 7;
}).factory('authInterceptor', function($rootScope, $q, $cookieStore, $location) {
	return {
		// Add authorization token to headers
		request: function(config) {
			config.headers = config.headers || {};
			// if ($cookieStore.get('token')) {
			// 	config.headers.Authorization = 'Bearer ' + $cookieStore.get('token');
			// }
			return config;
		},

		// Intercept 401s and redirect you to login
		responseError: function(response) {
			if (response.status === 401) {
				$location.path('/auth/login');
				// remove any stale tokens
				// $cookieStore.remove('token');
				return $q.reject(response);
			} else {
				return $q.reject(response);
			}
		}
	};
}).run(function($rootScope, $location, Auth, $cookies, _) {
	// Redirect to login if route requires auth and you're not logged in
	$rootScope.$on('$stateChangeStart', function(event, next) {
		var cookies = $cookies.getAll()
		_.each(cookies, function(value, key){
			var prefixes = ["wisp.design", "commands-", "design-", "snapshot-"]
			var found = _.find(prefixes, function(prefix){
				return key.indexOf(prefix) == 0
			})
			if (found){
				console.log("bad cookie: "+key)
				$cookies.remove(key)
			}
		})
		Auth.isLoggedInAsync(function(loggedIn) {
			if (next.authenticate && !loggedIn) {
				event.preventDefault();
				$location.path('/auth/login');
			}
		});
	});
});
app.filter('dateSuffix', function($filter) {
	var suffixes = ["th", "st", "nd", "rd"];
	return function(input) {
		var dtfilter = $filter('date')(input, 'dd');
		var day = parseInt(dtfilter, 10);
		var relevantDigits = (day < 30) ? day % 20 : day % 30;
		var suffix = (relevantDigits <= 3) ? suffixes[relevantDigits] : suffixes[0];
		return suffix;
	};
});
// draggable for winresults - will need to do different directives because of modal size, placement on click
app.directive('draggable', function($document) {
	return {
		restrict: 'AC',
		link: function(scope, iElement, iAttrs) {
			var startX = 0,
				startY = 0,
				x = 200,
				y = 50;

			var dialogWrapper = iElement.parent();
			dialogWrapper.css({
				position: 'absolute',
				left: '200px',
				top: '50px'
			});


			// make so only title bar is clickable for the drag start
			var titleBar = iElement.children().children(".modal-title");
			titleBar.on('mousedown', function(event) {
				// Prevent default dragging of selected content
				event.preventDefault();
				startX = event.pageX - x;
				startY = event.pageY - y;
				$document.on('mousemove', mousemove);
				$document.on('mouseup', mouseup);
			});

			function mousemove(event) {
				y = event.pageY - startY;
				x = event.pageX - startX;
				dialogWrapper.css({
					top: y + 'px',
					left: x + 'px'
				});
			}

			function mouseup() {
				$document.unbind('mousemove', mousemove);
				$document.unbind('mouseup', mouseup);
			}
		}
	};
});
app.directive('focusMe', function($timeout) {
	return {
		link: function(scope, element, attrs) {
			scope.$watch(attrs.focusMe, function(value) {
				if(value === true) { 
					$timeout(function() {
						var v = element[0]
						v.focus();
						scope[attrs.focusMe] = false;
						if (v && v.setSelectionRange) {
							v.setSelectionRange(0, v.value.length)
						}
					},250);
				}
			});
		}
	};
});
app.directive('preferenceSelection', function () {
	return {
		restrict: 'E',
		templateUrl: 'components/drawing_components/viewComponents/rightToolbar/preference-selection.html',
		scope: {
			preferenceName: '@',
			preferenceValue: '@',
			options: '=',
			design: '=', 
			paint: '&', 
			resetDesignElement: '&', 
			hasUserOverrides: '&',
			hasDesignOverrides: '&',
			customStyle: "="
		}
	};
});
app.directive('colorSelection', function () {
	return {
		restrict: 'E',
		templateUrl: 'components/drawing_components/viewComponents/rightToolbar/color-selection.html',
		scope: {
			preferenceName: '@',
			preferenceValue: '@',
			design: '=', 
			resetDesignElement: '&', 
			hasUserOverrides: '&',
			hasDesignOverrides: '&',
			colorPickerOptions: "=",
			eventApi: "="
		}
	};
});